<template>
<div>
  <div class="h-100" :class="loading ? 'blur-bg' : ''">
    <app-collapse class="mt-2">

      <!-- Behaviours -->
      <app-collapse-item title="" :is-visible="behaviours.length > 0">
        <template slot="header">
          <div class="flex-column w-100 cursor-default">
            <div class="d-inline-flex align-middle w-100">
              <b-badge class="mr-1" :variant="behaviours.length > 0 ? 'info' : 'muted'">
                {{ behaviours.length ? behaviours.length : '-' }}
              </b-badge>

              <div class="d-inline-flex justify-content-between w-100">
                <span class="font-weight-bold">Behaviours</span>
              </div>
            </div>
          </div>
        </template>

        <list-behaviour-nodes :all-behaviour-nodes="behaviours" hide-title />
      </app-collapse-item>

      <!-- Interfaces -->
      <app-collapse-item title="" :is-visible="interfaces.length > 0">
        <template slot="header">
          <div class="flex-column w-100 cursor-default">
            <div class="d-inline-flex align-middle w-100">
              <b-badge class="mr-1" :variant="interfaces.length > 0 ? 'info' : 'muted'">
                {{ interfaces.length ? interfaces.length : '-' }}
              </b-badge>

              <div class="d-inline-flex justify-content-between w-100">
                <span class="font-weight-bold">Interfaces</span>
                <b-link
                  title="Link Interfaces"
                  class="mr-2"
                  @click.stop="$bvModal.show('link-interfaces-to-requirements-modal')"
                >
                  <feather-icon icon="LinkIcon" class="text-success" />
                </b-link>
              </div>
            </div>
          </div>
        </template>

        <list-interfaces :all-interfaces="interfaces" hide-title />
      </app-collapse-item>

      <!-- Issues -->
      <app-collapse-item title="" :is-visible="issues.length > 0">
        <template slot="header">
          <div class="flex-column w-100 cursor-default">
            <div class="d-inline-flex align-middle w-100">
              <b-badge class="mr-1" :variant="issues.length > 0 ? 'info' : 'muted'">
                {{ issues.length ? issues.length : '-' }}
              </b-badge>

              <div class="d-inline-flex justify-content-between w-100">
                <span class="font-weight-bold">Issues</span>
                <b-link
                  title="Link Issues"
                  class="mr-2"
                  @click.stop="$bvModal.show('link-issues-to-requirements-modal')"
                >
                  <feather-icon icon="LinkIcon" class="text-success" />
                </b-link>
              </div>
            </div>
          </div>
        </template>

        <list-issues :all-issues="issues" hide-title />
      </app-collapse-item>

      <!-- Test Cases -->
      <app-collapse-item title="" :is-visible="tests.length > 0">
        <template slot="header">
          <div class="flex-column w-100 cursor-default">
            <div class="d-inline-flex align-middle w-100">
              <b-badge class="mr-1" :variant="tests.length > 0 ? 'info' : 'muted'">
                {{ tests.length ? tests.length : '-' }}
              </b-badge>

              <div class="d-inline-flex justify-content-between w-100">
                <span class="font-weight-bold">Test Cases</span>
                <b-link
                  title="Link Test Cases"
                  class="mr-2"
                  @click.stop="$bvModal.show('link-test-cases-to-requirements-modal')"
                >
                  <feather-icon icon="LinkIcon" class="text-success" />
                </b-link>
              </div>
            </div>
          </div>
        </template>

        <list-test-cases :all-tests="tests" hide-title />
      </app-collapse-item>

      <!-- Qualification Records -->
      <app-collapse-item title="" :is-visible="qualificationRecords.length > 0">
        <template slot="header">
          <div class="flex-column w-100 cursor-default">
            <div class="d-inline-flex align-middle w-100">
              <b-badge class="mr-1" :variant="qualificationRecords.length > 0 ? 'info' : 'muted'">
                {{ qualificationRecords.length ? qualificationRecords.length : '-' }}
              </b-badge>

              <div class="d-inline-flex justify-content-between w-100">
                <span class="font-weight-bold">Qualification Records</span>
                <b-link
                  title="Create and link a Qualification Record"
                  class="mr-2"
                  @click.stop="$bvModal.show('create-qualification-record-modal')"
                >
                  <feather-icon icon="PlusIcon" class="text-success" />
                </b-link>
              </div>
            </div>
          </div>
        </template>

        <list-qualification-records
          :parent-id="reqId"
          :all-qualification-records="qualificationRecords"
          hide-title
          @on-update="loadRequirementLinks"
        />
      </app-collapse-item>

      <!-- Releases -->
      <app-collapse-item title="">
        <template slot="header">
          <div class="flex-column w-100 cursor-default">
            <div class="d-inline-flex align-middle w-100">
              <b-badge class="mr-1" :variant="releases.length > 0 ? 'info' : 'muted'">
                {{ releases.length ? releases.length : '-' }}
              </b-badge>

              <div class="d-inline-flex justify-content-between w-100">
                <span class="font-weight-bold">Releases</span>
                <b-link
                  title="Link Releases"
                  class="mr-2"
                  @click.stop="$bvModal.show('link-releases-to-requirements-modal')"
                >
                  <feather-icon icon="LinkIcon" class="text-success" />
                </b-link>
              </div>
            </div>
          </div>
        </template>

        <list-releases :all-releases="releases" hide-title />
      </app-collapse-item>
    </app-collapse>
  </div>
  <div
    v-if="loading"
    id="loader"
    class="position-absolute position-top-0 w-100 vh-100 d-flex justify-content-center align-items-center"
    style="z-index: 900;"
  >
    <atom-spinner class="animate-pulse" />
  </div>
</div>
</template>

<script>
import store from '@/store'
import {
  onMounted,
  ref,
  watch,
} from '@vue/composition-api'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import AtomSpinner from '@/components/Spinners/AtomSpinner.vue'
import Ripple from 'vue-ripple-directive'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'

import ListBehaviourNodes from '@/views/RequirementsTable/components/ListBehaviourNodes.vue'
import ListInterfaces from '@/views/RequirementsTable/components/ListInterfaces.vue'
import ListIssues from '@/views/RequirementsTable/components/ListIssues.vue'
import ListQualificationRecords from '@/views/RequirementsTable/components/ListQualificationRecords.vue'
import ListReleases from '@/views/RequirementsTable/components/ListReleases.vue'
import ListTestCases from '@/views/RequirementsTable/components/ListTestCases.vue'

export default {
  name: 'SidebarTabLinks',
  directives: { Ripple },
  components: {
    AppCollapseItem,
    AtomSpinner,
    AppCollapse,
    ListBehaviourNodes,
    ListInterfaces,
    ListIssues,
    ListQualificationRecords,
    ListReleases,
    ListTestCases,
  },
  props: {
    reqId: {
      type: String,
      required: true,
    },
  },
  setup(props, context) {
    onMounted(() => { loadRequirementLinks() })
    watch(props, val => { loadRequirementLinks() })

    const loading = ref(false)
    const behaviours = ref([])
    const interfaces = ref([])
    const issues = ref([])
    const releases = ref([])
    const tests = ref([])

    // Objective, Quality, Evidence
    const oqe = ref([])
    const qualificationRecords = ref([])

    const loadRequirementLinks = () => {
      loading.value = true
      store
        .dispatch('requirement/getRequirementLinks', props.reqId)
        .then(response => {
          behaviours.value = response.behaviours
          interfaces.value = response.interfaces
          issues.value = response.issues
          oqe.value = response.oqe
          qualificationRecords.value = response.qualification_records
          releases.value = response.releases
          tests.value = response.tests
        })
        .finally(() => { loading.value = false })
    }

    return {
      loading,
      behaviours,
      interfaces,
      issues,
      oqe,
      qualificationRecords,
      releases,
      tests,

      loadRequirementLinks,
    }
  },
}
</script>

<style scoped lang="scss">
.blur-bg {
  filter: blur(3px) sepia(50%);
}
</style>
