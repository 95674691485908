<template>
  <div>
    <h6 v-if="!alternativeHeaders" class="text-info">
      {{ isForwardTrace ? 'Forward Trace Links' : 'Backward Trace Links' }}
    </h6>
    <div v-else class="font-medium-1 mb-50 d-inline-flex w-100 justify-content-between">
      {{ isForwardTrace ? 'Forward Trace Links' : 'Backward Trace Links' }}
      <b-link
        title="Create and link a Qualification Record"
        class="mr-2"
        @click.stop="openModal"
      >
        <feather-icon icon="PlusIcon" class="text-success" />
      </b-link>
    </div>

    <b-list-group v-if="allTraceItems && allTraceItems.length > 0" flush>
      <b-list-group-item
        v-for="(trace, index) in allTraceItems" :key="index"
        class="flex-column align-items-start cursor-pointer list-item"
        @click.stop="routeToRequirement(trace)"
        @click.middle.stop="routeToRequirementNewTab(trace)"
      >
        <div v-if="trace && trace.node" class="d-flex w-100 justify-content-between">
          <h6>
            <span class="text-primary">
              {{ trace.node.display_id }}:
            </span>
            <span class="font-small-3">{{ trace.node.spec_title }}</span>
          </h6>
          <div>
            <span
              class="py-25 px-50 border-rounded-2xl font-weight-bold font-small-2 text-info"
              style="border: 1px solid;"
            >
              {{ trace.link.type | capitalizeEachWord }}
            </span>
          </div>
        </div>

        <div v-if="trace.link.justification !== ''">
          <span class="font-small-2">Justification: </span>
          <p>{{ trace.link.justification }}</p>
        </div>
        <div v-else>
          <span class="font-small-1 text-muted">Justification: N/A</span>
        </div>
      </b-list-group-item>
    </b-list-group>
    <div v-else class="px-2">
      <span class="text-muted font-small-4">
        No trace links
      </span>
    </div>
  </div>
</template>

<script>
import { useRouter } from '@core/utils/utils'

export default {
  name: 'ListTrace',
  props: {
    allTraceItems: {
      type: Array,
      default: () => [],
    },
    isForwardTrace: {
      type: Boolean,
      required: false,
      default: false,
    },
    alternativeHeaders: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup(props, context) {
    const { route, router } = useRouter()

    const routeToRequirement = traceObj => {
      router.push({
        name: 'requirements_table_focus_on_id',
        params: { ...route.params, specId: traceObj.node.spec_id, focus: traceObj.node.id },
      })
    }

    const routeToRequirementNewTab = traceObj => {
      const routeData = router.resolve({
        name: 'requirements_table_focus_on_id',
        params: { ...route.params, specId: traceObj.node.spec_id, focus: traceObj.node.id },
      })
      window.open(routeData.href, '_blank')
    }

    const openModal = () => {
      if (props.isForwardTrace) {
        context.root.$bvModal.show('link-forward-trace-requirement-modal')
      } else {
        context.root.$bvModal.show('link-backward-trace-requirement-modal')
      }
    }

    return {
      routeToRequirement,
      routeToRequirementNewTab,
      openModal,
    }
  },
}
</script>

<style scoped lang="scss">
.list-item {
  border-left: 1px solid;
  border-right: 1px solid;
  border-radius: 1rem;
}
.list-item:not(:hover) {
  background-color: rgba(0, 0, 0, 0.1) !important;
}
</style>
