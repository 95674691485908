<template>
  <b-sidebar
    v-if="isVisible"
    id="requirementDetailsSidebar"
    ref="sidebar"
    class="resizable"
    :width="`${contextMenuWidth}px`"
    bg-variant="white"
    shadow
    right
    no-close-on-route-change
    :visible.sync="isVisible"
    @hidden="onHide"
  >
    <div class="resize-handle" @mousedown.prevent="initResize" />
    <div id="content">
      <h3 class="ml-2 mr-50">
        <span class="select-all mr-50">{{ selectedRequirement ? selectedRequirement.display_id : 'unset' }}</span>
        <span class="font-small-3">[ {{ selectedRequirement ? selectedRequirement.priority : 'unset' }} ]</span>
        <b-dropdown
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="flat-primary"
          style="float: right"
          size="sm"
        >
          <template #button-content>
            <feather-icon icon="SettingsIcon" class="mr-50" />
          </template>
          <b-dropdown-item @click="$bvModal.show('update-requirement-modal')">
            Update
          </b-dropdown-item>
          <!-- TODO: Restore Requirement #1 -->
          <!--<b-dropdown-item-->
          <!--  v-if="selected_requirement.properties.deleted"-->
          <!--  @click="$store.dispatch('requirements/restoreRequirement', selected_requirement.properties.id)"-->
          <!--&gt;-->
          <!--  <span class="text-success">Restore</span>-->
          <!--</b-dropdown-item>-->
          <!--<b-dropdown-divider />-->
          <!-- TODO: Move Requirement #1 -->
          <!--<b-dropdown-item @click="$bvModal.show('move-requirement-modal')">-->
          <!--  Move-->
          <!--</b-dropdown-item>-->
          <!-- TODO: Clone Requirement #1 -->
          <!--<b-dropdown-item @click="$bvModal.show('copy-requirement-modal')">-->
          <!--  Clone-->
          <!--</b-dropdown-item>-->
          <!--<b-dropdown-divider />-->
          <!-- TODO: Backward Trace #1 -->
          <!--<b-dropdown-item @click="$bvModal.show('backward-trace-requirement-modal')">-->
          <!--  Trace-->
          <!--</b-dropdown-item>-->
          <!-- TODO: Coverage #1 -->
          <!--<b-dropdown-item @click="$bvModal.show('forward-trace-requirement-modal')">-->
          <!--  Coverage-->
          <!--</b-dropdown-item>-->
          <!--<b-dropdown-divider />-->
          <!-- TODO: Parse Requirement #2 -->
          <!--<b-dropdown-item @click="$bvModal.show('parse-requirement-modal2')">-->
          <!--  Parse-->
          <!--</b-dropdown-item>-->
          <!--<b-dropdown-divider />-->
          <!-- TODO: Impact Analysis #1 -->
          <!--<b-dropdown-item @click="$bvModal.show('impact-requirement-modal')">-->
          <!--  Impact Analysis-->
          <!--</b-dropdown-item>-->
          <!-- TODO: Requirement History #2 -->
          <!--<b-dropdown-item @click="openHistory()">-->
          <!--  History-->
          <!--</b-dropdown-item>-->
          <b-dropdown-divider />
          <b-dropdown-item @click="$bvModal.show('delete-requirement-modal')">
            <span class="text-danger">Delete</span>
          </b-dropdown-item>
        </b-dropdown>
      </h3>
      <b-tabs id="menu" class="position-relative pb-5">
        <b-tab active title="Details" class="mx-2">
          <div id="details-form-conditional-wrapper">
            <sidebar-tab-details :req-id="selectedRequirement.id" />
          </div>
        </b-tab>

        <b-tab title="Allocations" @click="openTabAllocations">
          <div id="allocations-conditional-wrapper" class="mx-2">
            <sidebar-tab-allocations :req-id="selectedRequirement.id" />
          </div>
        </b-tab>

        <b-tab title="Links" @click="openTabAssociations">
          <div id="associations-conditional-wrapper" class="mx-2">
            <sidebar-tab-links :req-id="selectedRequirement.id" />
          </div>
        </b-tab>

        <b-tab title="Trace" @click="openTraceTab">
          <div id="trace-conditional-wrapper" class="mx-2">
            <sidebar-tab-trace :req-id="selectedRequirement.id" />
          </div>
        </b-tab>

        <b-tab title="Notes" @click="openTraceTab">
          <div id="trace-conditional-wrapper" class="mx-2">
            <sidebar-tab-notes :req-id="selectedRequirement.id" />
          </div>
        </b-tab>

        <b-tab title="History" @click="openHistoryTab">
          <div id="history-conditional-wrapper">
            <div class="d-flex justify-content-center align-items-center mt-4 mb-2">
              <atom-spinner class="animate-pulse" />
            </div>
            <div class="d-inline-flex w-100 align-middle justify-content-center">
              <span class="mr-75 font-medium-3 text-primary">
                Under Construction
              </span>
              <feather-icon icon="CoffeeIcon" size="24" />
            </div>
          </div>
        </b-tab>
      </b-tabs>
    </div>
  </b-sidebar>
</template>

<script>
import { computed, ref } from '@vue/composition-api'
import Ripple from 'vue-ripple-directive'
import AtomSpinner from '@/components/Spinners/AtomSpinner.vue'
import SidebarTabAllocations from './SidebarTabAllocations.vue'
import SidebarTabDetails from './SidebarTabDetails.vue'
import SidebarTabLinks from './SidebarTabLinks.vue'
import SidebarTabNotes from './SidebarTabNotes.vue'
import SidebarTabTrace from './SidebarTabTrace.vue'

export default {
  name: 'RequirementContextSidebar',
  directives: { Ripple },
  components: {
    AtomSpinner,
    SidebarTabAllocations,
    SidebarTabDetails,
    SidebarTabLinks,
    SidebarTabNotes,
    SidebarTabTrace,
  },
  props: {
    visible: {
      type: Boolean,
      required: true,
      default: false,
    },
    selectedRequirement: {
      type: Object,
      required: true,
    },
  },
  setup(props, context) {
    // Tab Logic (placeholders for future expansion)
    const openTabAllocations = () => {}
    const openTabAssociations = () => {}
    const openTraceTab = () => {}
    const openHistoryTab = () => {}

    // Sidebar Show/Hide logic
    const isVisible = computed(() => props.visible)
    const onHide = event => {
      // Update the parent component 'visible' prop value, so it knows that its closed
      context.emit('update:visible', false)
    }

    // Resize logic
    const sidebar = ref(null)
    const contextMenuWidth = ref((localStorage.getItem('requirementTableSidebarWidth') || 450))
    const initResize = event => {
      window.addEventListener('mousemove', startResizing)
      window.addEventListener('mouseup', stopResizing)
    }
    const startResizing = event => {
      const currentSidebarWidth = parseInt(sidebar.value.width, 10)
      const newWidth = currentSidebarWidth - (event.clientX - document.getElementById('requirementDetailsSidebar').getBoundingClientRect().left)
      contextMenuWidth.value = Math.max(newWidth, 450)
      localStorage.setItem('requirementTableSidebarWidth', `${contextMenuWidth.value}`)
    }
    const stopResizing = event => {
      window.removeEventListener('mousemove', startResizing)
      window.removeEventListener('mouseup', stopResizing)
    }

    return {
      openTabAllocations,
      openTabAssociations,
      openTraceTab,
      openHistoryTab,

      sidebar,
      isVisible,
      onHide,
      contextMenuWidth,
      initResize,
    }
  },
}
</script>

<style lang="scss">
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';

.blur-bg {
  filter: blur(3px) sepia(50%);
}

body.dark-layout {
  // Loading panel background colour
  .b-overlay {
    .bg-light {
      background-color: $theme-dark-card-bg !important;
    }
  }
}

.resizable {
  position: relative; /* Needed to position the resize handle */
  overflow: hidden;
}

.resize-handle {
  z-index: 99999;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 10px; /* Width of the handle */
  cursor: ew-resize; /* Horizontal resize cursor */
}

.resize-handle:hover {
  border-left: 2px solid rgb(133, 184, 255);
}
</style>
