<template>
  <b-modal
    id="create-requirement-modal"
    title="Create Requirement"
    no-close-on-esc
    no-close-on-backdrop
    ok-title="Create Requirement"
    cancel-title="Discard"
    ok-variant="success"
    cancel-variant="outline-secondary"
    @show="onShow"
    @ok="onSubmit"
    @cancel="onCancel"
  >
    <!-- Section & Priority -->
    <div class="w-100 d-inline-flex justify-content-between mb-1">
      <div class="w-100">
        <LabelWithHoverIcon
          label-string="Priority"
          :popover-text="`Requirement priority level (Default: ${defaultPriority})`"
        />

        <b-form-select
          v-model="selectedPriority"
          :options="priorityOptions"
        />
      </div>
      <div class="mx-2" />
      <div class="w-100">
        <LabelWithHoverIcon
          label-string="Security Classification"
          :popover-text="`Australian Government PSF Security classification.(Default: ${defaultSecurityClassification})`"
        />
        <b-form-select
          v-model="selectedSecurityClassification"
          :options="securityClassificationOptions"
        />
      </div>
    </div>

    <!-- Object Text -->
    <div class="d-flex flex-column w-100 mb-1">
      <div class="d-inline-flex justify-content-between align-items-center mb-50">
        <LabelWithHoverIcon
          class="flex-grow-1"
          label-string="Requirement Definition"
          popover-text="The Requirement itself, 'Use Case' format is preferred. Enable the 'Expand' option for requirements with a lot of content."
        />
        <div class="d-inline-flex align-items-center">
          <b-form-checkbox
            :checked="expandRequirementDescription"
            switch
            class="custom-control-info"
            @change="expandRequirementDescription = !expandRequirementDescription"
          >
            <span class="switch-icon-left">
              <feather-icon
                icon="Minimize2Icon"
                class="cursor-pointer"
              />
            </span>
            <span class="switch-icon-right">
              <feather-icon
                icon="Maximize2Icon"
                class="cursor-pointer"
              />
            </span>
          </b-form-checkbox>
          <span class="font-small-3">
            {{ expandRequirementDescription ? 'Collapse' : 'Expand' }}
          </span>
        </div>
      </div>
      <tip-tap-editor
        v-model="objectText"
        placeholder="Compose a requirement..."
        min-height="20"
        :max-height="expandRequirementDescription ? '' : '20'"
      />
    </div>

    <!-- Additional attributes -->
    <div class="d-flex flex-column w-100">
      <!-- Label Row -->
      <div class="d-inline-flex justify-content-between align-items-center mb-50">
        <LabelWithHoverIcon
          class="flex-grow-1"
          label-string="Additional attributes"
          popover-text="Extra properties to add to a requirement"
        />
        <div class="d-inline-flex align-items-center">
          <b-form-checkbox
            :checked="showAdditionalAttributes"
            switch
            class="custom-control-info"
            @change="showAdditionalAttributes = !showAdditionalAttributes"
          >
            <span class="switch-icon-left">
              <feather-icon
                icon="Minimize2Icon"
                class="cursor-pointer"
              />
            </span>
            <span class="switch-icon-right">
              <feather-icon
                icon="Maximize2Icon"
                class="cursor-pointer"
              />
            </span>
          </b-form-checkbox>
          <span class="font-small-3">
            Attributes {{ showAdditionalAttributes ? 'shown' : 'hidden' }}
          </span>
        </div>
      </div>

      <!-- Attribute Rows (Repeated) -->
      <div v-if="showAdditionalAttributes">
        <div
          v-for="(item, index) in additionalAttributes"
          :key="`additional-attribute-row-${index}`"
          class="flex-column w-100"
        >
          <div class="w-100 d-inline-flex justify-content-between my-1">
            <div class="form-label-group w-100 ml-50 mb-0">
              <b-form-input
                :id="`txt-attribute-name-${index}`"
                v-model="item.key"
                placeholder="Attribute name"
              />
              <label :for="`txt-attribute-name-${index}`">Attribute name</label>
            </div>

            <div class="mx-50" />

            <div class="form-label-group w-100 ml-50 mb-0">
              <b-form-input
                :id="`txt-attribute-value-${index}`"
                v-model="item.value"
                :placeholder="`${item.key ? item.key : 'Attribute'} value`"
              />
              <label :for="`txt-attribute-value-${index}`">{{ item.key ? item.key : 'Attribute' }} value</label>
            </div>
            <b-button
              class="ml-50"
              variant="flat-danger"
              @click="additionalAttributes.splice(index, 1)"
            >
              <feather-icon icon="Trash2Icon" />
            </b-button>
          </div>
        </div>

        <div>
          <b-button
            size="sm"
            class="ml-50"
            variant="flat-success"
            @click="additionalAttributes.push({ key: '', value: '' })"
          >
            <feather-icon icon="PlusIcon" />
            Add New
          </b-button>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import store from '@/store'
import { computed, ref } from '@vue/composition-api'
import LabelWithHoverIcon from '@/components/Forms/LabelWithHoverIcon.vue'
import TipTapEditor from '@/components/Forms/TipTapEditor/TipTapEditor.vue'

export default {
  name: 'CreateRequirementModal',
  components: {
    LabelWithHoverIcon,
    TipTapEditor,
  },
  setup(props, context) {
    // Get list of columns for the additional attributes section
    const fetchCustomColumns = () => {
      store
        .dispatch('requirementsTable/getAdditionalCustomColumns')
        .then(columns => {
          additionalAttributes.value = columns.map(item => ({ key: item, value: '' }))
        })
    }

    const clearForm = () => {
      objectText.value = ''
      selectedPriority.value = defaultPriority
      selectedSecurityClassification.value = defaultSecurityClassification
    }

    const onShow = () => {
      fetchCustomColumns()
      clearForm()
    }

    const onCancel = () => { clearForm() }

    const onSubmit = () => {
      const attributeObj = additionalAttributes.value.reduce((acc, { key, value }) => {
        acc[key] = value
        return acc
      }, {})
      const data = {
        priority: selectedPriority.value,
        classification: selectedSecurityClassification.value,
        object_text: objectText.value,
        additional_attributes: attributeObj,
      }
      store
        .dispatch('requirement/createRequirement', data)
        .then(() => {
          context.emit('on-submit')
        })
    }

    // Form properties
    const defaultPriority = store.state.constants.defaultRequirementPriority.id
    const defaultSecurityClassification = store.state.constants.defaultSecurityClassification.id
    const priorityOptions = computed(() => store.getters['constants/requirementPriorities'])
    const securityClassificationOptions = computed(() => store.getters['constants/securityClassifications'])
    const expandRequirementDescription = ref(false)
    const showAdditionalAttributes = ref(true)

    // Requirement details
    const objectText = ref('')
    const selectedPriority = ref(defaultPriority)
    const selectedSecurityClassification = ref(defaultSecurityClassification)
    const additionalAttributes = ref([])

    return {
      onShow,
      onSubmit,
      onCancel,

      defaultPriority,
      priorityOptions,
      defaultSecurityClassification,
      securityClassificationOptions,
      expandRequirementDescription,
      showAdditionalAttributes,

      objectText,
      selectedPriority,
      selectedSecurityClassification,
      additionalAttributes,
    }
  },
}

</script>

<style lang="scss">
// Set the modal width manually because the default Bootstrap ones aren't sufficient
#create-requirement-modal .modal-dialog {
  max-width: 1200px;
}
</style>
