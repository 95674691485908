<template>
<div>
  <div class="h-100" :class="loading ? 'blur-bg' : ''">
    <list-group-entity
      pre-label="Allocated"
      label="Actors / Performers"
      :entity-array="performers"
      modal="allocate-req-performers-modal"
      class="my-2"
    />
    <list-group-entity
      pre-label="Allocated"
      label="Enablers"
      :entity-array="enablers"
      modal="allocate-req-enablers-modal"
      class="mb-2"
    />
    <list-group-entity
      pre-label="Allocated"
      label="Beneficiaries"
      :entity-array="beneficiaries"
      modal="allocate-req-beneficiaries-modal"
      class="mb-2"
    />
    <list-group-entity
      pre-label="Allocated"
      label="Functions"
      :entity-array="functions"
      modal="allocate-req-functions-modal"
      class="mb-2"
    />
    <list-group-entity
      pre-label="Allocated"
      label="Interfaces"
      :entity-array="interfaces"
      modal="allocate-req-interfaces-modal"
      class="mb-2"
    />
    <list-group-entity
      pre-label="Allocated"
      label="Objectives"
      :entity-array="objectives"
      modal="allocate-req-objectives-modal"
      class="mb-2"
    />
    <list-group-entity
      pre-label="Allocated"
      label="Standards / References"
      :entity-array="standards"
      modal="allocate-req-standards-modal"
      class="mb-2"
    />
    <list-group-entity
      pre-label="Allocated"
      label="Resources / Information / Data / Other"
      :entity-array="resources"
      modal="allocate-req-allocations-modal"
      class="mb-2"
    />
  </div>
  <div
    v-if="loading"
    id="loader"
    class="position-absolute position-top-0 w-100 vh-100 d-flex justify-content-center align-items-center"
    style="z-index: 900;"
  >
    <atom-spinner class="animate-pulse" />
  </div>

  <!-- Associators -->
  <div v-for="assc in associators" :key="assc.singular">
    <GenericAssociator
      :id="`allocate-req-${getPlural(assc)}-modal`"
      :fetch-filter-fn="fetchSharedModels"
      :fetch-fn="modelId => fetchComponents(modelId, `${getLeftOpts(assc)}`)"
      :update-fn="payload => linkComponent(payload, assc.singular.toLowerCase())"
      :remove-fn="payload => unlinkComponent(payload)"
      :initial-prefilter="selectedModel"
      :initial-list="fields[`selected_${getPlural(assc)}`]"
      prefilter-label="name"
      :type-name="assc.label"
    >
      <template #left="cpt">
        <b-badge v-for="l in cpt.labels.filter(lb => lb !== 'Component')" :key="l" class="mr-1" variant="primary">
          ({{ l }})
        </b-badge>
        &nbsp;
        <span :title="cpt.name" class="ml-1 mr-1 text-ellipsis">{{ cpt.name }} </span>
      </template>
      <template #right="{ cpt, triggerSave }">
        <ComponentAllocatedListItem
          :component-badge="assc.singular"
          :component="cpt"
          :trigger-fn="triggerSave"
        />
      </template>
    </GenericAssociator>
  </div>
</div>
</template>

<script>
import ComponentAllocatedListItem from '@/components/Forms/ComponentAllocatedListItem.vue'
import GenericAssociator from '@/components/Forms/GenericAssociator.vue'
import coreService from '@/libs/api-services/core-service'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  computed,
  onMounted,
  ref,
  watch,
} from '@vue/composition-api'
import AtomSpinner from '@/components/Spinners/AtomSpinner.vue'
import ListGroupEntity from '@/components/Forms/ListGroups/ListGroupEntity.vue'
import Ripple from 'vue-ripple-directive'

export default {
  name: 'RequirementAllocations',
  directives: { Ripple },
  components: {
    GenericAssociator,
    ComponentAllocatedListItem,
    ListGroupEntity,
    AtomSpinner,
  },
  props: {
    reqId: {
      type: String,
      required: true,
    },
  },
  setup(props, context) {
    onMounted(() => { loadRequirementAllocations() })
    watch(props, val => { loadRequirementAllocations() })

    const loading = ref(false)
    const performers = ref([])
    const enablers = ref([])
    const beneficiaries = ref([])
    const functions = ref([])
    const interfaces = ref([])
    const objectives = ref([])
    const standards = ref([])
    const resources = ref([])

    const loadRequirementAllocations = async () => {
      reset()
      loading.value = true
      try {
        await store.dispatch('domainModel/getComponents')
        const response = await store.dispatch('requirement/getRequirementAllocations', props.reqId)
        performers.value = response.performers
        enablers.value = response.enablers
        beneficiaries.value = response.beneficiaries
        functions.value = response.functions
        interfaces.value = response.interfaces
        objectives.value = response.objectives
        standards.value = response.standards
        resources.value = response.resources
      } catch (e) {
        console.error('Failed to load RequirementAllocations', e)
      } finally {
        loading.value = false
      }
    }
    const reset = () => {
      loading.value = false
      performers.value = []
      enablers.value = []
      beneficiaries.value = []
      functions.value = []
      interfaces.value = []
      objectives.value = []
      standards.value = []
      resources.value = []
    }

    // Generic associator stuff
    const selectedModel = computed(() => store.state.model)
    const fields = computed(() => ({
      selected_performers: performers.value,
      selected_enablers: enablers.value,
      selected_beneficiaries: beneficiaries.value,
      selected_functions: functions.value,
      selected_interfaces: interfaces.value,
      selected_objectives: objectives.value,
      selected_standards: standards.value,
      selected_allocations: resources.value,
    }))
    const associators = [
      { singular: 'Performer', label: 'Actors / Performers' },
      { singular: 'Enabler', label: 'Enablers' },
      {
        singular: 'Beneficiary',
        label: 'Beneficiaries',
        plural: 'beneficiaries',
        leftOpts: 'performers',
      },
      { singular: 'Function', label: 'Functions' },
      { singular: 'Interface', label: 'Interfaces' },
      { singular: 'Objective', label: 'Objectives' },
      { singular: 'Standard', label: 'Standards / References' },
      {
        singular: 'Resource',
        label: 'Resources',
        plural: 'allocations',
        leftOpts: 'resources',
      },
    ]
    const getPlural = obj => obj?.plural || `${obj.singular.toLowerCase()}s`
    const fetchSharedModels = async () => [store.state.model, ...await coreService.modelApi.getLinkedModels(store.state.model.id)]
    const fetchComponents = async (modelId, subtype) => {
      await store.dispatch('domainModel/getComponentsForModel', { modelId, subtype: '' })
      if (modelId && store.state.model.id !== modelId) {
        // now returns all components, not just the subtype
        return store.state.domainModel[modelId].components
      }
      return store.state.domainModel.components
    }
    const linkComponent = (cpt, allocationType) => {
      if (Array.isArray(cpt)) { [cpt] = cpt }
      coreService.componentApi
        .createComponentRequirementAllocation(cpt.id, {
          req_id: props.reqId,
          justification: cpt.justification,
          allocation_type: allocationType,
        })
        .then(() => {
          context.root.$toast({
            component: ToastificationContent,
            props: {
              title: 'Updated Component Allocation',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
        })
    }
    const unlinkComponent = cptId => {
      coreService.componentApi
        .deleteComponentRequirementAllocation(cptId, props.reqId)
        .then(() => {
          context.root.$toast({
            component: ToastificationContent,
            props: {
              title: 'Removed Component Allocation',
              icon: 'TrashIcon',
              variant: 'danger',
            },
          })
        })
    }
    const getLeftOpts = obj => obj?.leftOpts || getPlural(obj)

    return {
      loading,
      performers,
      enablers,
      beneficiaries,
      functions,
      interfaces,
      objectives,
      standards,
      resources,

      selectedModel,
      fields,
      associators,
      getPlural,
      fetchSharedModels,
      fetchComponents,
      linkComponent,
      unlinkComponent,
      getLeftOpts,
    }
  },
}
</script>

<style scoped lang="scss">
.blur-bg {
  filter: blur(3px) sepia(50%);
}
</style>
