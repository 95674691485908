<template>
  <div>
    <h6 v-if="!hideTitle" class="text-info">
      Linked Qualification Records
    </h6>

    <b-list-group v-if="allQualificationRecords && allQualificationRecords.length > 0" flush>
      <b-list-group-item
        v-for="(qR, index) in allQualificationRecords" :key="index"
        class="flex-column align-items-start cursor-pointer list-item py-75"
        style="cursor: default"
      >
        <div class="d-inline-flex w-100 justify-content-between align-top mb-50">
          <div class="flex-column">
            <div>
              <feather-icon
                icon="ToolIcon"
                class="text-primary"
                size="16"
              />
              <span class="ml-50 font-small-3">
                <span class="text-primary mr-25">Qualification Method:</span>
                <span :class="qR.method ? 'font-weight-bold' : 'text-muted'">
                  {{ qR.method || 'n/a' }}
                </span>
              </span>
            </div>
            <div>
              <feather-icon
                icon="RepeatIcon"
                class="text-primary"
                size="16"
              />
              <span class="ml-50 font-small-3">
                <span class="text-primary mr-25">Qualification Phase:</span>
                <span :class="qR.phase ? 'font-weight-bold' : 'text-muted'">
                  {{ qR.phase || 'n/a' }}
                </span>
              </span>
            </div>
            <div>
              <feather-icon
                icon="ClipboardIcon"
                class="text-primary"
                size="16"
              />
              <div class="d-inline ml-50 font-small-3">
                <span class="text-primary mr-25">Qualification Statement:</span>
                <p class="ml-3 mb-0" v-html="qR.statement" />
              </div>
            </div>
            <div>
              <font-awesome-icon
                :icon="['fas', 'vials']"
                class="text-primary mr-25"
              />
              <span class="ml-50 font-small-3">
                <span class="text-primary mr-25">Result:</span>
                <span v-if="qR.record_result === 'Fail'" class="font-weight-bolder text-danger">
                  {{ qR.record_result }}
                </span>
                <span v-else :class="qR.record_result ? 'font-weight-bold' : 'text-muted'">
                  {{ qR.record_result || 'Not determined' }}
                </span>
              </span>
            </div>
            <div>
              <feather-icon
                icon="ClipboardIcon"
                class="text-primary"
                size="16"
              />
              <div class="d-inline ml-50 font-small-3">
                <span class="text-primary mr-25">OQE Cited:</span>
                <ul v-if="qR.evidence.length > 0" class="mb-0 pt-25">
                  <li v-for="evidence in qR.evidence" :key="evidence.id">
                    <a :href="evidence.document_link" target="_blank">
                      {{ evidence.title }} (v{{ evidence.version }})
                    </a>
                  </li>
                </ul>
                <span v-else>None</span>
              </div>
            </div>
          </div>
          <div v-if="hideTitle" class="mt-0 mb-0">
            <b-button
              variant="flat-primary"
              size="sm"
              class="btn-icon"
              @click="openLinkEvidenceModal(qR.id)"
            >
              <feather-icon icon="LinkIcon" />
            </b-button>
            <b-button
              variant="flat-success"
              size="sm"
              class="btn-icon"
              @click="openUpdateModal(qR.id)"
            >
              <feather-icon icon="EditIcon" />
            </b-button>
            <b-button
              variant="flat-danger"
              size="sm"
              class="btn-icon"
              @click="openDeleteModal(qR.id)"
            >
              <feather-icon icon="TrashIcon" />
            </b-button>
          </div>
        </div>
      </b-list-group-item>
    </b-list-group>
    <div v-else class="px-2">
      <span class="text-muted font-small-4">
        No linked qualification records
      </span>
    </div>

    <div id="listQualificationRecordModals">
      <create-qualification-record-modal
        :parent-id="parentId"
        @on-create="onUpdate"
      />
      <update-qualification-record-modal
        v-if="selectedQualificationRecordId"
        :qr-id="selectedQualificationRecordId"
        @on-update="onUpdate"
      />
      <delete-qualification-record-modal
        v-if="selectedQualificationRecordId"
        :qr-id="selectedQualificationRecordId"
        @on-delete="onUpdate"
      />
      <link-evidence
        v-if="selectedQualificationRecordId"
        :qr-id="selectedQualificationRecordId"
        @on-linked="onUpdate"
      />
    </div>
  </div>
</template>

<script>
import CreateQualificationRecordModal from '@/components/ObjectiveQualificationEvidence/Record/CreateQualificationRecordModal.vue'
import UpdateQualificationRecordModal from '@/components/ObjectiveQualificationEvidence/Record/UpdateQualificationRecordModal.vue'
import DeleteQualificationRecordModal from '@/components/ObjectiveQualificationEvidence/Record/DeleteQualificationRecordModal.vue'
import LinkEvidence from '@/components/ObjectiveQualificationEvidence/Record/LinkEvidence.vue'
import { nextTick, ref } from '@vue/composition-api'

export default {
  name: 'ListQualificationRecords',
  components: {
    CreateQualificationRecordModal,
    UpdateQualificationRecordModal,
    DeleteQualificationRecordModal,
    LinkEvidence,
  },
  props: {
    parentId: {
      type: String,
      required: true,
    },
    allQualificationRecords: {
      type: Array,
      default: () => [],
    },
    hideTitle: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, context) {
    const selectedQualificationRecordId = ref('')

    const openUpdateModal = id => {
      selectedQualificationRecordId.value = id
      nextTick(() => { context.root.$bvModal.show(`update-qualification-record-${id}`) })
    }
    const openDeleteModal = id => {
      selectedQualificationRecordId.value = id
      nextTick(() => { context.root.$bvModal.show(`delete-qualification-record-${id}`) })
    }
    const openLinkEvidenceModal = id => {
      selectedQualificationRecordId.value = id
      nextTick(() => { context.root.$bvModal.show(`link-evidence-with-qualification-record-${id}`) })
    }

    const onUpdate = () => {
      context.emit('on-update')
      selectedQualificationRecordId.value = null
    }

    return {
      selectedQualificationRecordId,

      openUpdateModal,
      openDeleteModal,
      openLinkEvidenceModal,

      onUpdate,
    }
  },
}
</script>

<style scoped lang="scss">
.list-item {
  border-left: 1px solid;
  border-right: 1px solid;
  border-radius: 1rem;
}
.list-item:not(:hover) {
  background-color: rgba(0, 0, 0, 0.1) !important;
}
</style>
