import store from '@/store'
import { useRouter } from '@core/utils/utils'
import { max, min, range } from 'lodash'

export default function utilsRowSelection(selectedRowIndexes) {
  const { route, router } = useRouter()

  // Keep track of last selected row, useful for Shift+Click
  let lastSelectedRowIndex

  const autoSelect = reqIds => {
    reqIds.forEach(r => {
      console.log(r)
    })
  }

  const handleSelection = event => {
    // Clicked HTML element
    const el = event.currentTarget

    // Basic Requirement information for the given row
    const reqId = el.id
    const clickedRowIndex = store.state.requirementsTable.allRequirements.findIndex(r => r.id === reqId)
    const isClickedRowSelected = selectedRowIndexes.value.includes(clickedRowIndex)

    if (event.button === 2 && event.ctrlKey && isClickedRowSelected) {
      // If triggered by a right-click + ctrl event and the row is already selected
      //  don't reset the selection
    } else if (event.button === 2 && isClickedRowSelected) {
      // If triggered by a right-click event and the row is already selected
      //  don't reset the selection
    } else if (event.ctrlKey || event.metaKey) {
      if (event.shiftKey) {
        const start = min([clickedRowIndex, lastSelectedRowIndex])
        const end = max([clickedRowIndex, lastSelectedRowIndex]) + 1

        if (!isClickedRowSelected) {
          selectedRowIndexes.value.push(...range(start, end))
        } else {
          selectedRowIndexes.value = selectedRowIndexes.value.filter(i => !range(start, end).includes(i))
        }
      } else {
        // Ctrl or Cmd for macOS
        // Note: Leave the eslint-ignore statement here, abiding by it will change the logic
        //  and I don't have the time to figure that shit out. It works as is, so that's good.
        // eslint-disable-next-line no-lonely-if
        if (selectedRowIndexes.value.includes(clickedRowIndex)) {
          // Remove from selectedRowIndexes
          selectedRowIndexes.value = selectedRowIndexes.value.filter(index => index !== clickedRowIndex)
        } else {
          // Add to selectedRowIndexes
          selectedRowIndexes.value.push(clickedRowIndex)
        }
      }
    } else if (event.shiftKey) {
      // Calculate range and select all rows in between + last row
      const start = min([clickedRowIndex, lastSelectedRowIndex])
      const end = max([clickedRowIndex, lastSelectedRowIndex]) + 1
      selectedRowIndexes.value.push(...range(start, end))
    } else {
      // Simple click without modifiers
      selectedRowIndexes.value = [clickedRowIndex]
    }

    // Remove duplicates, if any, and sort ascending
    selectedRowIndexes.value = Array.from(new Set(selectedRowIndexes.value))
    selectedRowIndexes.value.sort((a, b) => a - b)

    // Update visual state of rows
    lastSelectedRowIndex = clickedRowIndex
    applyRowSelectionStyles()
  }

  /**
   * Select all rows
   */
  const selectAllRequirements = () => {
    const indexes = []
    store.state.requirementsTable.allRequirements.forEach((_, index) => {
      indexes.push(index)
    })
    selectedRowIndexes.value = indexes
    lastSelectedRowIndex = undefined
    applyRowSelectionStyles()
  }

  /**
   * Reset the selected rows and their styling of all rows back to their default (non-selected) state.
   */
  const clearSelectedRequirements = () => {
    selectedRowIndexes.value = []
    lastSelectedRowIndex = undefined
    applyRowSelectionStyles()
  }

  /**
   * Updates the selection state of row elements in the DOM.
   *
   * Iterate over each row element that has the 'lazy-loaded' class and updates its selection state and toggle
   * the 'selected' class on each row element.
   */
  const applyRowSelectionStyles = () => {
    const rows = Array.from(document.querySelectorAll('.lazy'))

    rows.forEach((rowElement, index) => {
      if (selectedRowIndexes.value.includes(index)) {
        rowElement.classList.add('selected')
      } else {
        rowElement.classList.remove('selected')
      }
    })
  }

  const keyboardShortcuts = event => {
    if (event.key === 'Escape') {
      // Escape key clears selection
      clearSelectedRequirements()
    }

    // FIXME: Disabled due to inadvertently triggering when trying to select all text within any textbox on the page
    // if (event.key === 'a' && (event.ctrlKey || event.metaKey)) {
    //   // Select all keyboard shortcut selects all rows (Ctrl (Cmd for macOS) + A)
    //   selectAllRequirements()
    //   event.preventDefault() // Prevent default browser behavior (e.g., selecting all text in an input field)
    // }
  }

  /**
   * Prevents text selection when the 'Shift' key is pressed.
   *
   * Useful in scenarios where holding 'Shift' is used for selecting multiple items in a list (e.g., rows in a table),
   * and accidental text selection would be annoying.
   *
   * It should be attached to relevant keyboard event listeners where this behavior is desired.
   * Example usage (you *MUST* remove the listeners on un-mounted):
   *  Add listeners (add to 'onMounted()')
   *       window.addEventListener('keyup', preventTextSelectionOnShiftKey)
   *       window.addEventListener('keydown', preventTextSelectionOnShiftKey)
   *
   *  Remove listeners (add to 'onUnmounted()')
   *       window.removeEventListener('keyup', preventTextSelectionOnShiftKey)
   *       window.removeEventListener('keydown', preventTextSelectionOnShiftKey)
   *
   * @param {KeyboardEvent} event - The keyboard event object.
   */
  const preventTextSelectionOnShiftKey = event => {
    document.onselectstart = () => !(event.key === 'Shift' && event.shiftKey)
  }

  const loadRowSelectionEventListeners = () => {
    window.addEventListener('keyup', preventTextSelectionOnShiftKey)
    window.addEventListener('keydown', preventTextSelectionOnShiftKey)
    window.addEventListener('keydown', keyboardShortcuts)
  }

  const unloadRowSelectionEventListeners = () => {
    window.removeEventListener('keyup', preventTextSelectionOnShiftKey)
    window.removeEventListener('keydown', preventTextSelectionOnShiftKey)
    window.removeEventListener('keydown', keyboardShortcuts)

    // Reset text selection back to default behaviour
    document.onselectstart = null
  }
  const handleRouteQueryOnRowSelection = () => {
    // Function to handle router navigation to avoid repetition
    const navigate = queryObject => {
      router.replace({
        name: 'requirements_table',
        params: { ...route.params },
        query: queryObject,
      }).catch(err => {
        if (err.name !== 'NavigationDuplicated') throw err
      })
    }

    if (selectedRowIndexes.value.length === 1) {
      const reqId = store.state.requirementsTable.allRequirements[selectedRowIndexes.value[0]].id
      navigate({ selectedRequirement: reqId })
    } else if (selectedRowIndexes.value.length > 1) {
      // Handle bulk edit
      navigate({ selectedRequirement: 'multiple' })
    } else {
      // Handle nothing selected, clear the route query params
      navigate({})
    }
  }

  return {
    autoSelect,
    handleSelection,
    clearSelectedRequirements,
    loadRowSelectionEventListeners,
    unloadRowSelectionEventListeners,
    handleRouteQueryOnRowSelection,
  }
}
